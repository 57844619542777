import classnames from "classnames";
import Loader from "components/Loader";
import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";

import style from "./style.module.css";

function CustomLink(props) {
  if (props.to) {
    return (
      <Link
        onClick={props.handleOnClick}
        className={props.finalClassName}
        to={props.to}
      >
        {props.children}
      </Link>
    );
  }

  return (
    <a
      onClick={props.handleOnClick}
      className={props.finalClassName}
      href={props.href}
      name={props.nameBtn}
      target={props.target}
    >
      {props.children}
    </a>
  );
}

export default class Button extends Component {
  handleOnClick() {
    const { onClick, type } = this.props;
    if (
      onClick &&
      type !== ButtonType.LOADING &&
      type !== ButtonType.DISABLED
    ) {
      onClick();
    }
  }

  render() {
    const { children, type, href, rootClassName, icon, to, nameBtn, target } =
      this.props;
    const finalClassName = classnames(style.root, rootClassName, {
      [style.normalStyle]: type === ButtonType.NORMAL,
      [style.ghostStyle]: type === ButtonType.GHOST,
      [style.bordered]: type === ButtonType.BORDERED,
      [style.linkStyle]: type === ButtonType.LINK,
      [style.disabled]: type === ButtonType.DISABLED,
      [style.loginNormal]: type === ButtonType.LOGIN,
      [style.loginGhost]: type === ButtonType.LOGINGHOST,
      [style.loginBordered]: type === ButtonType.LOGINBORDERED,
      [style.loginLink]: type === ButtonType.LOGINLINK,
      [style.loginDisabled]: type === ButtonType.LOGINDISABLED,
    });

    return (
      <CustomLink
        nameBtn={nameBtn}
        to={to}
        href={href}
        finalClassName={finalClassName}
        handleOnClick={this.handleOnClick.bind(this)}
        target={target}
      >
        {type === ButtonType.LOADING && <Loader />}
        {type !== ButtonType.LOADING && (
          <Fragment>
            {icon && <span className={style.icon}>{icon}</span>}
            {children}
          </Fragment>
        )}
      </CustomLink>
    );
  }
}

export const ButtonType = {
  NORMAL: "NORMAL",
  GHOST: "GHOST",
  LOADING: "LOADING",
  BORDERED: "BORDERED",
  LINK: "LINK",
  DISABLED: "DISABLED",
  LOGIN: "LOGIN",
  LOGINGHOST: "LOGINGHOST",
  LOGINBORDERED: "LOGINBORDERED",
  LOGINLINK: "LOGINLINK",
  LOGINDISABLED: "LOGINDISBLED",
};

Button.defaultProps = {
  children: "",
  type: ButtonType.NORMAL,
  icon: undefined,
};

Button.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  type: PropTypes.string,
  onClick: PropTypes.func,
};
